ion-toast.custom-toast {

  &::part(message) {

  }

  &::part(container) {
    background: rgba(227, 227, 227, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 14px;
  }

  --background: transparent;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);

}


